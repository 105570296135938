'use client';

import React, { useLayoutEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Info, Settings } from 'lucide-react';
import classNames from 'classnames';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import { InputV2 } from '@/components/Input';
import { PaymentMethodSelector } from './components/Services/PaymentMethodSelector';
import { ServiceTable } from './components/Services/ServiceTable';
import { PolicySelector } from './components/Toggle/PolicySelector';
import { Layout } from '../../components';
import { GetServicesI } from '@/types/cyclone/requests';
import { ServiceI, ServiceStatusE, WalletTypeE } from '@/types/cyclone/models';
import { useClient } from '@/hooks';
import { useQuery } from 'react-query';
import { trackGenericEvent } from '@/analytics';
import { toast } from 'react-toastify';
import { DownpaymentOption } from '@/containers/ActionServiceV2/context/service';
import { useAuth } from '@/contexts';
import { DepositType, PolicyType, ServiceData } from './lib/type';
import useLog from '@/hooks/useLog';

export default function AdjustDownpayment() {
  const { client } = useClient();
  const { logAndNotify } = useLog();
  const { session, reloadSession } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const vendor = session?.vendor;

  const isStripeConnected =
    session?.vendor?.payment_gateways?.some((pg) => pg === WalletTypeE.STRIPE) || false;

  const [isEnabled, setIsEnabled] = useState(false);
  const [depositType, setDepositType] = useState(DepositType.FIXED);
  const [depositValue, setDepositValue] = useState<number>(0);
  const [depositValueUsd, setDepositValueUsd] = useState<number>(0);
  const [policyType, setPolicyType] = useState<PolicyType>(PolicyType.SAME);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<string[]>([]);
  const [servicesData, setServicesData] = useState<ServiceData[]>([]);
  const [depositValueError, setDepositValueError] = useState<string>('');

  const minServiceValue = useMemo(() => {
    if (servicesData.length === 0) return 0;
    return Math.min(...servicesData.map((service) => service.totalValue));
  }, [servicesData]);

  //initial values
  useLayoutEffect(() => {
    if (vendor) {
      setIsEnabled(vendor.is_down_payment_enabled);
      setDepositType(
        vendor.down_payment_amount && vendor.down_payment_amount > 0
          ? DepositType.FIXED
          : DepositType.PERCENTAGE
      );
      setDepositValue(
        vendor.down_payment_amount && vendor.down_payment_amount > 0
          ? vendor.down_payment_amount
          : vendor.down_payment_amount_discount && vendor.down_payment_amount_discount > 0
          ? vendor.down_payment_amount_discount * 100
          : 0
      );
      setDepositValueUsd(vendor.down_payment_amount_usd || 0);
      setPolicyType(vendor.is_down_payment_global ? PolicyType.SAME : PolicyType.DIFFERENT);
      setSelectedPaymentMethods(() => {
        const methods = [];
        if (vendor.down_payment_is_mercado_pago_available) {
          methods.push(DownpaymentOption.MERCADO_PAGO);
        }
        if (vendor.down_payment_is_bank_transfer_available) {
          methods.push(DownpaymentOption.BANK_TRANSFER);
        }
        if (vendor.down_payment_is_cash_available) {
          methods.push(DownpaymentOption.CASH);
        }
        return methods;
      });
    }
  }, []);

  useLayoutEffect(() => {
    validateDepositValue(depositValue);
  }, [depositValue, depositType, minServiceValue]);

  const validateDepositValue = (value: number) => {
    if (depositType === DepositType.PERCENTAGE) {
      if (value > 100) {
        setDepositValueError('El porcentaje no puede ser mayor a 100%');
        return false;
      }
    }

    setDepositValueError('');
    return true;
  };

  const getAvailablePMOptions = () => {
    const options = [];
    const isWalletAssociated =
      session?.vendor?.payment_gateways && session.vendor.payment_gateways.length > 0;

    if (isWalletAssociated) {
      options.push(DownpaymentOption.MERCADO_PAGO);
    }
    if (session?.vendor?.is_payment_bank_transfer_enabled) {
      options.push(DownpaymentOption.BANK_TRANSFER);
    }
    if (session?.vendor?.is_payment_cash_enabled) {
      options.push(DownpaymentOption.CASH);
    }
    return options;
  };

  const handleOnChangeDepositType = (value: DepositType) => {
    setDepositType(value);

    if (value === DepositType.FIXED) {
      setDepositValue(vendor?.down_payment_amount || 0);
      setDepositValueUsd(0);
    } else {
      setDepositValue((vendor?.down_payment_amount_discount as number) * 100 || 0);
      setDepositValueUsd(0);
    }
  };

  const mapServiceData = (service: ServiceI): ServiceData => ({
    id: service.id.toString(),
    name: service.name,
    totalValue: service.price,
    depositAmount: service.down_payment?.amount || 0,
    depositPercentage:
      (service.down_payment?.amount_discount && service.down_payment?.amount_discount * 100) || 0,
    hasChanges: false,
    isEditing: false,
    depositAmountUsd: service.down_payment?.amount_usd || 0
  });

  const { refetch, isLoading } = useQuery(
    ['services', 'compressed'],
    async () =>
      await client<GetServicesI>(`me/vendor/services/compressed`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      onSuccess(data) {
        const filteredServices = data?.filter((service) => service.status === ServiceStatusE.ACTIVE);
        setServicesData(filteredServices?.map(mapServiceData) || []);
      }
    }
  );

  const handleSelectPaymentMethod = (value: string) => {
    setSelectedPaymentMethods((current) =>
      current.includes(value) ? current.filter((item) => item !== value) : [...current, value]
    );
  };

  const handleDepositValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepositValue(+e.target.value);
  };

  const handleDepositValueUsdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepositValueUsd(+e.target.value);
  };

  const createPaymentMethodsPayload = () => {
    return {
      is_mercado_pago_available: selectedPaymentMethods.includes(DownpaymentOption.MERCADO_PAGO),
      is_bank_transfer_available: selectedPaymentMethods.includes(DownpaymentOption.BANK_TRANSFER),
      is_cash_available: selectedPaymentMethods.includes(DownpaymentOption.CASH)
    };
  };

  const validateConfigurations = () => {
    if (!isEnabled) return true;

    if (selectedPaymentMethods.length === 0) {
      toast.error('Debes seleccionar al menos un método de pago');
      return false;
    }

    if (policyType === PolicyType.SAME) {
      if (depositValue <= 0) {
        toast.error('El valor de ajuste debe ser mayor a 0');
        return false;
      }

      if (depositValueError) {
        toast.error(depositValueError);
        return false;
      }
    }

    if (policyType === PolicyType.DIFFERENT) {
      if (servicesData.some((service) => service.isEditing === true)) {
        toast.warning(
          'Tienes cambios pendientes en algunos servicios. Por favor, confirma antes de realizar el ajuste'
        );
        return false;
      }

      if (servicesData.every((service) => service.hasChanges === false)) {
        toast.warning('No tienes ninguna modificación pendiente');
        return false;
      }
    }

    return true;
  };

  const createSubmitPayload = () => {
    const paymentMethodsPayload = createPaymentMethodsPayload();

    if (!isEnabled) {
      return {
        is_down_payment_enabled: false,
        is_down_payment_global: false,
        down_payment: []
      };
    }

    if (policyType === PolicyType.DIFFERENT) {
      const servicesWithChanges = servicesData.filter((service) => service.hasChanges === true);
      const downPaymentPayload = servicesWithChanges.map((service) => ({
        service_id: service.id,
        amount_discount: depositType === DepositType.PERCENTAGE ? service.depositPercentage : 0,
        amount: depositType === DepositType.FIXED ? service.depositAmount : 0,
        amount_usd: depositType === DepositType.FIXED ? service.depositAmountUsd : 0
      }));

      return {
        is_down_payment_enabled: true,
        is_down_payment_global: false,
        down_payment: downPaymentPayload,
        ...paymentMethodsPayload
      };
    } else {
      // Same policy type
      const downPaymentPayload = {
        ...(depositType === DepositType.FIXED
          ? { amount: depositValue, amount_usd: depositValueUsd, amount_discount: 0 }
          : { amount_discount: depositValue, amount: 0, amount_usd: 0 })
      };

      return {
        is_down_payment_enabled: true,
        is_down_payment_global: true,
        down_payment: downPaymentPayload,
        ...paymentMethodsPayload
      };
    }
  };

  const handleUpdateDownPayment = async () => {
    trackGenericEvent('Button Adjust Downpayment Globally Clicked', {
      type: 'Button Adjust Downpayment Globally Clicked'
    });

    if (!validateConfigurations()) return;

    try {
      setIsSubmitting(true);
      const payload = createSubmitPayload();

      const response = await client('services/change-down-payment', 'PUT', {
        data: payload,
        isAuthRequired: true
      });

      if (response) {
        toast.success('Señas actualizadas exitosamente');
        await refetch(), reloadSession(); // Refresh services list
      }
    } catch (error) {
      logAndNotify(
        `Error al actualizar las señas globales, the data is ${JSON.stringify(
          createSubmitPayload()
        )} and the error is ${error}`
      );
      toast.error('Error al actualizar las señas');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout
      title="Seña"
      onSave={() => handleUpdateDownPayment()}
      isLoading={isSubmitting || isLoading}
      subtitle="Modificar las señas de tus servicios"
    >
      <div className="w-full max-w-lg mx-auto space-y-6 text-[#212121]">
        <div
          className={classNames(
            'p-5 rounded-lg border-2 transition-all duration-300 hover:shadow-sm',
            isEnabled ? 'border-primary bg-primary/5' : 'border-muted bg-muted/10'
          )}
        >
          <div className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
              id="toggle-switch"
              className="scale-125 accent-primary cursor-pointer"
            />
            <label htmlFor="toggle-switch" className="text-lg font-medium cursor-pointer">
              ¿Sólo cobrar seña para reservar?
            </label>
          </div>
        </div>

        <AnimatePresence>
          {isEnabled && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className="overflow-hidden"
            >
              <div className="space-y-6 mb-4">
                <div className="p-4 bg-gray-100 rounded-md text-sm border">
                  Al ingresar una reserva para tus servicios, tus clientes visualizarán la seña que deben
                  pagar por anticipado para asegurar su turno.
                </div>
                {/* Payment Methods */}
                <div className="space-y-3">
                  <label className="font-medium text-slate-700">Medios de pago aceptados para señas</label>
                  <PaymentMethodSelector
                    selectedMethods={selectedPaymentMethods}
                    onSelectMethod={handleSelectPaymentMethod}
                    availableMethods={getAvailablePMOptions()}
                  />
                </div>
                {/* Notes Box */}
                <div className="bg-blue/5 border border-[#0072ff]/20 rounded-lg p-4 text-sm text-blue">
                  <div className="flex items-start">
                    <Info className="h-5 w-5 mr-3 mt-0.5 flex-shrink-0 text-blue" />
                    <div className="space-y-2">
                      <p className="font-semibold">Notas importantes:</p>
                      <ul className="list-disc pl-5 space-y-2">
                        <li>
                          Para pagos en efectivo o por transferencia, deberás gestionar el cobro de la seña
                          con tu cliente.
                        </li>
                        <li>
                          Los descuentos por medio de pago se aplicarán tanto a la seña como al valor total
                          del servicio.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Policy Selector */}
                <div className="space-y-3">
                  <label className="font-medium text-slate-700">Política de señas</label>
                  <PolicySelector
                    value={policyType}
                    onChange={(value) => setPolicyType(value as PolicyType)}
                  />
                </div>
                {policyType === PolicyType.SAME ? (
                  <div className="space-y-2">
                    {/* Deposit Type Section */}
                    <label htmlFor="deposit-type" className="font-medium text-slate-700 flex items-center">
                      <Settings className="w-4 h-4 mr-2 text-primary" />
                      Tipo de seña
                    </label>
                    <div className="flex gap-2 w-full">
                      <div className="w-[35%]">
                        <Select
                          value={depositType}
                          onValueChange={(value) => handleOnChangeDepositType(value as DepositType)}
                        >
                          <SelectTrigger
                            id="deposit-type"
                            className="bg-white border-slate-200 hover:border-primary transition-colors"
                          >
                            <SelectValue placeholder="Seleccionar tipo de seña" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="fixed">Monto fijo ($)</SelectItem>
                            <SelectItem value="percentage">Porcentaje (%)</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      {/* Deposit Value Input */}
                      <div className="flex gap-2 w-[65%]">
                        <InputV2
                          id="deposit-value"
                          type="number"
                          value={depositValue}
                          onChange={handleDepositValueChange}
                          postfix={depositType === DepositType.PERCENTAGE ? '%' : '$'}
                          placeholder={
                            depositType === DepositType.FIXED ? 'Ingresa el monto' : 'Ingresa el porcentaje'
                          }
                          error={depositValueError}
                        />
                        {depositType === DepositType.FIXED && isStripeConnected && (
                          <InputV2
                            id="deposit-value-usd"
                            value={depositValueUsd}
                            onChange={handleDepositValueUsdChange}
                            postfix="US$"
                            placeholder="Ingresa el monto en dolares"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <label className="font-medium text-slate-700">Configuración por servicio</label>
                    <div className="p-3 bg-[#0072ff]/5 border border-[#0072ff]/20 rounded-md text-sm text-[#0072ff] mt-2">
                      <div className="flex items-start">
                        <Info className="h-5 w-5 mr-2 mt-0.5 flex-shrink-0 text-[#0072ff]" />
                        <p>
                          Al configurar una política distinta por servicio, puedes personalizar el monto o
                          porcentaje de seña para cada uno de tus servicios.
                        </p>
                      </div>
                    </div>
                    <ServiceTable
                      services={servicesData}
                      setServices={setServicesData}
                      policyType={policyType}
                      depositInputType={depositType}
                      setDepositInputType={setDepositType}
                      isStripeConnected={isStripeConnected}
                    />
                  </div>
                )}

                {policyType === PolicyType.SAME && (
                  <ServiceTable
                    services={servicesData}
                    setServices={setServicesData}
                    policyType={policyType}
                    depositInputType={depositType}
                    setDepositInputType={setDepositType}
                    isStripeConnected={isStripeConnected}
                  />
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Layout>
  );
}
