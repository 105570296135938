import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { SessionI } from '@/types/cyclone/requests';

const AMPLITUDE_API_KEY = '9b9866917c3165f29b0383a54679ca7';
const AMPLITUDE_ONBOARDING_API_KEY = '945d9ea3de50bf1411000163cbe2f313';
const AMPLITUDE_NOT_BSC_API_KEY = '63e938dda2168126b8f93456d5e66d08';

let amplitudeOnboardingInstance: ReturnType<typeof amplitude.createInstance> | null = null;
let amplitudeNotBSCInstance: ReturnType<typeof amplitude.createInstance> | null = null;

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
  }
}

export interface CustomProps {
  screen: Screen;
  [propName: string]: unknown;
}

const getUTMParameters = () => {
  if (typeof window === 'undefined') return {}; // Ensure this runs in the browser

  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const params: any = {};

  utmParams.forEach((param) => {
    const value = searchParams.get(param);
    if (value) params[param] = value;
  });

  return params;
};

export const initAmplitude = (session: SessionI | null) => {
  const isOnboarding = session?.vendor?.is_onboarding_finished === false;
  const isNotBSC =
    (session?.vendor?.vendor_activity_tracking?.first_payment_method_at === null ||
      session?.vendor?.vendor_activity_tracking?.first_availability_at === null ||
      session?.vendor?.vendor_activity_tracking?.first_service_at === null) &&
    session?.vendor?.is_onboarding_finished === true;
  const isProd = import.meta.env.VITE_NODE_ENV === 'production';

  if (!session) {
    console.log('No session, not initializing amplitude');
    return;
  }

  // Initialize main instance for everyone
  amplitude.init(AMPLITUDE_API_KEY, {
    trackingOptions: {
      ipAddress: true,
      language: true,
      platform: true
    },
    logLevel: amplitude.Types.LogLevel.Warn
  });

  // Initialize separate instance just for session replay during onboarding
  if (isProd && isOnboarding && session) {
    amplitudeOnboardingInstance = amplitude.createInstance();

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1.0
    });

    amplitudeOnboardingInstance.add(sessionReplayTracking);
    amplitudeOnboardingInstance.init(AMPLITUDE_ONBOARDING_API_KEY, {
      defaultTracking: {
        sessions: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false
      }
    });
  }

  if (isProd && isNotBSC && session) {
    amplitudeNotBSCInstance = amplitude.createInstance();

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1.0
    });

    amplitudeNotBSCInstance.add(sessionReplayTracking);
    amplitudeNotBSCInstance.init(AMPLITUDE_NOT_BSC_API_KEY, {
      // Disable default tracking since we only want session replay, just like onboarding
      defaultTracking: {
        sessions: false,
        pageViews: false,
        formInteractions: false,
        fileDownloads: false
      },
      // Keep these tracking options
      trackingOptions: {
        ipAddress: true,
        language: true,
        platform: true
      }
    });
  }
};

export const identifyUser = (session: SessionI): void => {
  amplitude.setUserId(session.id.toString());

  let paymentMethods = '';
  if (session.vendor?.payment_gateways && session.vendor.payment_gateways.length > 0) {
    paymentMethods = session.vendor.payment_gateways.join(', ');
  }
  if (session.vendor?.is_payment_bank_transfer_enabled) {
    paymentMethods += (paymentMethods ? ', ' : '') + 'Bank Transfer';
  }
  if (session.vendor?.is_payment_cash_enabled) {
    paymentMethods += (paymentMethods ? ', ' : '') + 'Cash';
  }

  paymentMethods = paymentMethods || 'No payment methods available';

  const identify = new amplitude.Identify();
  identify.set('first_name', session.first_name || '');
  identify.set('last_name', session.last_name || '');
  identify.set('username', session.vendor?.username || '');
  identify.set('email', session.email || '');
  identify.set('name', session.first_name + ' ' + session.last_name);
  identify.set('phone', session.phone || '');
  identify.set('role', session.role || '');
  identify.set('plan', session.vendor?.plan_name || '');
  identify.set('price_monthly', session.active_plan ? session.active_plan.price_monthly : '');
  identify.set('log_in', session.connection_type);
  identify.set('payment_methods', paymentMethods);
  identify.set('first_service_date_creation', session.first_service_date_creation || '');
  identify.set('first_confirmed_booking_date', session.first_confirmed_booking_date || '');
  identify.set('first_basic_storefront_date', session.first_basic_storefront_date || '');
  identify.set('total_confirmed_bookings', session.total_confirmed_bookings || 0);
  identify.set(
    'vendor_status',
    session.vendor?.vendor_status_history?.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0]?.status || ''
  );

  amplitude.identify(identify);

  if (amplitudeOnboardingInstance) {
    amplitudeOnboardingInstance.setUserId(session.id.toString());
    amplitudeOnboardingInstance.identify(identify);
  }

  if (amplitudeNotBSCInstance) {
    amplitudeNotBSCInstance.setUserId(session.id.toString());
    amplitudeNotBSCInstance.identify(identify);
  }
};

export const trackGenericEvent = (name: string, customProps?: Partial<CustomProps>): void => {
  const utmParams = getUTMParameters();

  if (import.meta.env.VITE_NODE_ENV === 'production') {
    // Only track in main instance
    amplitude.track(name, {
      ...customProps,
      ...utmParams
    });
  } else {
    console.log('Event:', name, customProps);
  }
};

// Same for trackGenericPage
export const trackGenericPage = (name: string, customProps?: Partial<CustomProps>): void => {
  const utmParams = getUTMParameters();

  amplitude.track(`Viewed ${name} Page`, {
    ...customProps,
    ...utmParams
  });
};

export const sendToGTM = (event: string): void => {
  if (import.meta.env.VITE_NODE_ENV == 'production')
    window.dataLayer.push({
      event
    });
  else return;
};
